// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-role-selection-card .ant-btn-default {
  font-size: 12px;
}
.standard-role-selection-card .ant-form-item {
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_request_details/components/standard_role_selection_card/standard_role_selection_card.less"],"names":[],"mappings":"AAEI;EACE,eAAA;AADN;AAII;EACE,kBAAA;AAFN","sourcesContent":[".standard-role-selection-card {\n  .ant {\n    &-btn-default {\n      font-size: 12px;\n    }\n\n    &-form-item {\n      margin-bottom: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
