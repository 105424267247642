// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}
.card-label h6 {
  font-size: 12px;
  margin: 0;
  color: #75777d;
  font-family: "RWESansWeb-Regular", sans-serif;
}
.card-label label {
  font-size: 12px;
  font-family: "RWESansWeb-Medium", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/card_label/card_label.less"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;AADF;AAHA;EAOI,eAAA;EACA,SAAA;EACA,cAAA;EACA,6CAAA;AADJ;AATA;EAcI,eAAA;EACA,4CAAA;AAFJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.card-label {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  width: 90%;\n\n  h6 {\n    font-size: 12px;\n    margin: 0;\n    color: @font-02;\n    font-family: \"RWESansWeb-Regular\", sans-serif;\n  }\n\n  label {\n    font-size: 12px;\n    font-family: \"RWESansWeb-Medium\", sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
