import React from "react";

import "./card_label.less";
import { Tooltip } from "antd";

interface CardLabelProps {
  label: string;
  toolTip?: string;
  children: React.ReactNode | string;
  showToolTip?: boolean;
}

const CardLabel: React.FC<CardLabelProps> = ({
  label,
  toolTip,
  children,
  showToolTip = true,
}) => {
  return (
    <div className="card-label">
      <h6>{label}</h6>
      <label>
        <Tooltip placement="top" title={showToolTip ? toolTip || children : ""}>
          {children}
        </Tooltip>
      </label>
    </div>
  );
};

export default CardLabel;
