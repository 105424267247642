import React from "react";

import "./requested_role_renderer.less";
import comment from "@src/assets/icons/comment.svg";
import { ROLES } from "@src/constants";
import useFeatureToggle from "@src/hooks/use_feature_toggle";
import {
  setDetailsModalVisible,
  setSelectedRequestId,
} from "@src/services/requestOverviewSlice";
import { useAppDispatch } from "@src/setupStore";
import { CustomCellRendererProps } from "ag-grid-react";
import { Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import {
  setResourcePlanDetailsModalVisibility,
  setSelectedResourcePlanRequestId,
} from "@src/services/resourcePlanModalSlice";

interface RequestedRoleRendererParams {
  props: CustomCellRendererProps;
}

const RequestedRoleRenderer: React.FC<RequestedRoleRendererParams> = ({
  props,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isTLModalDetailsEnabled = useFeatureToggle("tlWindowedRequestDetails");

  /* ToDo: when updating endpoints of Staffing Request, 
      the field "projectRoleRequestId" in the DTO should be renamed to
      "roleRequestId" */
  const roleRequestId =
    props.data.projectRoleRequestId || props.data.roleRequestId;

  const { basicRoleName, roleSpecificationName, actionRequired, isUpdated } =
    props.data;

  const commentAvailable =
    props.data.commentAvailable && !props.data.commentReadByEmployee;

  function onClick() {
    switch (props.context?.view) {
      case ROLES.TEAM_LEAD:
        handleTeamLeadViewNavigation();
        break;
      case ROLES.PROJECT_LEAD:
        handleProjectLeadViewNavigation();
    }
  }

  function handleProjectLeadViewNavigation() {
    dispatch(setResourcePlanDetailsModalVisibility(true));
    dispatch(setSelectedResourcePlanRequestId(roleRequestId));
  }

  function handleTeamLeadViewNavigation() {
    if (!isTLModalDetailsEnabled) {
      history.push(`staffingRequests/suggestTeamMember/${roleRequestId}`);
    } else {
      dispatch(setDetailsModalVisible(true));
      dispatch(setSelectedRequestId(roleRequestId));
    }
  }

  return (
    <div className="requested-role-renderer">
      <div className="requested-role-renderer__standard-role-wrapper">
        <div className="requested-role-renderer__updated-section">
          {isUpdated && (
            <div className="requested-role-renderer__updated-dot" role="circle">
              <span />
            </div>
          )}
        </div>

        <div className="requested-role-renderer__standard-role">
          <Button
            type="link"
            className={
              "requested-role-renderer__button " +
              (actionRequired ? "requested-role-renderer__button--bold" : "")
            }
            onClick={onClick}
          >
            {roleRequestId} {basicRoleName}
          </Button>

          <div className="requested-role-renderer__role-specification">
            {roleSpecificationName}
          </div>
        </div>
      </div>

      <div>
        {commentAvailable && (
          <Tooltip
            mouseEnterDelay={0.5}
            placement="top"
            title="A comment is available"
          >
            <img src={comment} alt="comment" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default RequestedRoleRenderer;
