import React from "react";

import { ReactComponent as HandShakeIcon } from "@src/assets/icons/handshake.svg";
import { CardLabel } from "@src/components/card_label";
import { RoleRequest } from "@src/types/role_request_types";
import { Card, Row, Col, Flex, Form, Checkbox } from "antd";
import "./role_request_information_card.less";
import dayjs from "dayjs";

interface RequestInformationCardProps {
  request: RoleRequest;
}

const RoleRequestInformationCard: React.FC<RequestInformationCardProps> = ({
  request,
}) => {
  const dateTooltip = "Date Format: DD/MM/YYYY";

  return (
    <Card title="Request" className="role-request-information-card info-card">
      <Row justify="space-between">
        <Col>
          <Flex vertical gap={16}>
            <CardLabel label="Request sent" toolTip={dateTooltip}>
              {request
                ? dayjs(request.requestSentDate).format("DD/MM/YYYY")
                : ""}
            </CardLabel>

            <CardLabel label="Initially sent to">
              {request?.teamLead?.fullName}
            </CardLabel>

            {request?.forwardedTo && (
              <CardLabel label="Forwarded to">
                {request?.forwardedTo?.fullName}
              </CardLabel>
            )}
          </Flex>
        </Col>

        <Col>
          <Form initialValues={{ standardRole: request?.jointVenture }}>
            <Form.Item name="standardRole" valuePropName="checked">
              <Checkbox>
                <span className="role-request-information-card__joint-venture-label">
                  Joint Venture <HandShakeIcon role="icon" />
                </span>
              </Checkbox>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default RoleRequestInformationCard;
