import React from "react";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { clearResourcePlanModal } from "@src/services/resourcePlanModalSlice";
import { useAppSelector, RootState } from "@src/setupStore";
import { Button } from "antd";
import { useDispatch } from "react-redux";

const ActionHandlers: React.FC = () => {
  const dispatch = useDispatch();

  const { selectedStandardRole } = useAppSelector(
    (state: RootState) => state.resourcePlanDetailsModalSlice
  );

  function onClose() {
    dispatch(clearResourcePlanModal());
  }

  return (
    <>
      <Button size="large" icon={<CloseOutlined />} onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        disabled={!selectedStandardRole}
        icon={<CheckOutlined />}
      >
        Apply Changes
      </Button>
    </>
  );
};

export default ActionHandlers;
