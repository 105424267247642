import React, { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { REQUEST_STATUS } from "@src/constants";
import { ResourcePlanToolBar } from "@src/features/resource_plan_v2/components/resource_plan_tool_bar";
import { mapRoleRequestData } from "@src/features/resource_plan_v2/utils/resource_plan_table_utils";
import { RoleRequestDetailsModal } from "@src/features/role_request_details/components/role_request_details_modal";
import {
  filterMapForUpdatedValues,
  toggleErrorFlagInContext,
} from "@src/features/staffing_request_details/utils/propose_allocations_utils";
import { ActiveFilterBar } from "@src/features/table_filtering/components/active_filter_bar";
import {
  useGetRoleRequestsByProjectIdQuery,
  useUpdateRoleRequestMutation,
} from "@src/services/slices/projectsApi";
import {
  AllocationRowData,
  ResourcePlanTableRowData,
  RoleAllocationDetailsDTO,
  RoleRequest,
  UpdateRoleRequestPayload,
} from "@src/types/role_request_types";
import { isValueValid } from "@src/utils/edit_allocation_utils";
import {
  ColumnVisibleEvent,
  GridApi,
  GridReadyEvent,
  FilterChangedEvent,
  IRowNode,
} from "ag-grid-community";
import type { CellValueChangedEvent } from "ag-grid-community/dist/types/core/events";
import { AgGridReact } from "ag-grid-react";
import { App } from "antd";
import dayjs from "dayjs";
import _ from "lodash";

import { useResourcePlanStateHandler } from "../../hooks/use_resource_plan_state_handler";
import {
  getColumnDefs,
  getGridOptions,
} from "../../utils/resource_plan_table_grid_options";

import "./resource_plan_table.less";

interface ResourcePlanTableProps {
  projectId: string;
  projectEndDate: Date;
}

const ResourcePlanTable: React.FC<ResourcePlanTableProps> = ({
  projectId,
  projectEndDate,
}) => {
  const { message } = App.useApp();
  const gridApi = useRef<GridApi | null>(null);

  const columnDefs = useMemo(() => {
    return getColumnDefs(projectEndDate);
  }, [projectEndDate]);

  const { data: roleRequestData, isFetching } =
    useGetRoleRequestsByProjectIdQuery(projectId);
  const [updateRoleRequestMutation] = useUpdateRoleRequestMutation();

  const mappedRoleRequestData: ResourcePlanTableRowData[] = useMemo(
    () => mapRoleRequestData(roleRequestData),
    [roleRequestData]
  );

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useResourcePlanStateHandler({
      gridApi: gridApi.current,
      columnDefs,
    });

  /**
   * AG Grid Ready event
   *
   * @param {GridReadyEvent} event - AG Grid event parameter
   */
  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  /**
   * AG Grid Filter Changed event to show/hide no rows overlay
   *
   * @param event
   */
  function onFilterChanged(event: FilterChangedEvent): void {
    const displayedRowCount: number = event.api.getDisplayedRowCount();

    if (displayedRowCount === 0) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  }

  /**
   * Selects rows in the AG Grid where any allocation has a valid `updatedPercentage`.
   *
   * This function iterates over all grid rows and selects those where at least one allocation
   * has a non-null, valid `updatedPercentage`.
   */
  function handleRowSelection(
    params: CellValueChangedEvent<ResourcePlanTableRowData>
  ) {
    const { data } = params.node;

    if (data.status === REQUEST_STATUS.DRAFT) return;

    // At least one allocation has a valid updatedPercentage.
    const shouldSelect: boolean = [...params.data.allocations.values()].some(
      (allocation: AllocationRowData) =>
        allocation.updatedPercentage &&
        isValueValid(allocation.updatedPercentage)
    );

    params.node.setSelected(shouldSelect);
  }

  /**
   * Iterates over grid nodes and updates draft requests that have allocation changes.
   */
  function handleDraftUpdate() {
    if (!gridApi.current) return;

    gridApi.current.forEachNode((node: IRowNode<ResourcePlanTableRowData>) => {
      const request: ResourcePlanTableRowData = node.data;
      if (request.status !== REQUEST_STATUS.DRAFT) return;

      const newAllocations = getAllocationChangesForRequest(request);
      if (newAllocations.length === 0) return;

      updateRoleRequest(request, newAllocations);
    });
  }

  /**
   * Extracts valid allocation changes from a draft request.
   *
   * @param request - The resource plan request data.
   * @returns An array of allocation details.
   */
  function getAllocationChangesForRequest(request: ResourcePlanTableRowData) {
    const newAllocations: RoleAllocationDetailsDTO[] = [];
    const changedAllocations: Map<string, AllocationRowData> =
      filterMapForUpdatedValues(request.allocations);

    // Iterate over the allocations and extract valid allocation changes
    for (const [dateKey, allocation] of changedAllocations) {
      // Abort if updated percentage is not valid
      if (!isValueValid(allocation.updatedPercentage)) return [];

      const [month, year] = dateKey.split("-");
      const allocationDate = dayjs(`${year}-${month}-01`);
      newAllocations.push({
        year: allocationDate.year(),
        month: allocationDate.month() + 1,
        date: allocationDate.utc(true).format(),
        requiredPercentage: allocation.updatedPercentage,
      } as RoleAllocationDetailsDTO);
    }
    return newAllocations;
  }

  /**
   * Sends an update for a draft role request with the new allocation changes.
   *
   * @param request - The resource plan request data.
   * @param newAllocations - The list of updated allocation details.
   */
  async function updateRoleRequest(
    request: ResourcePlanTableRowData,
    newAllocations: RoleAllocationDetailsDTO[]
  ) {
    const updateRequestPayload: UpdateRoleRequestPayload = {
      isJointVenture: request.jointVenture,
      isDraft: true,
      scope: request.scope,
      specificRole: request.specificRole,
      projectIdentifier: request.projectIdentifier,
      allocations: newAllocations,
    };

    const roleRequest: RoleRequest = await updateRoleRequestMutation({
      projectId: projectId,
      roleRequestId: request.roleRequestId.toString(),
      requestPayload: updateRequestPayload,
    }).unwrap();

    const mappedRoleRequest = mapRoleRequestData([roleRequest]);
    gridApi.current.applyTransaction({ update: mappedRoleRequest });
  }

  /**
   * Handles cell value changes in the AG Grid.
   *
   * - `handleRowSelection` is called immediately.
   * - `handleDraftUpdate` is debounced to prevent excessive updates.
   *
   * @param {CellValueChangedEvent<ResourcePlanTableRowData>} event - The event object containing details about the changed cell.
   */
  function onCellValueChanged(
    event: CellValueChangedEvent<ResourcePlanTableRowData>
  ) {
    toggleErrorFlagInContext(event, message);
    handleRowSelection(event); // Immediate execution
    debouncedHandleDraftUpdate(event); // Debounced execution
  }

  /**
   * Debounced version of `handleDraftUpdate` to reduce frequent updates.
   */
  const debouncedHandleDraftUpdate = _.debounce(() => {
    handleDraftUpdate();
  }, 200);

  return (
    <div className="resource-plan-table">
      <RoleRequestDetailsModal />
      <ResourcePlanToolBar
        isFetching={isFetching}
        roleRequestData={roleRequestData}
        columnDefs={columnDefs}
        projectId={projectId}
        gridApi={gridApi.current}
      />

      <ActiveFilterBar
        activeFilters={activeFilters}
        onRemove={(key: string, value: string) =>
          resetActiveFilters(key, value)
        }
        onRemoveAll={() => resetActiveFilters()}
      />
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={mappedRoleRequestData}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(event: ColumnVisibleEvent) =>
            event.api.sizeColumnsToFit()
          }
          onFilterChanged={onFilterChanged}
          onCellValueChanged={onCellValueChanged}
          columnDefs={getColumnDefs(projectEndDate)}
          onFirstDataRendered={setTableRendered}
        />
      </div>
    </div>
  );
};

export default React.memo(ResourcePlanTable);
