import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { HTTP_METHODS } from "@src/constants";
import { RequestReplies } from "@src/types";
import { RoleRequest } from "@src/types/role_request_types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const projectRoleRequestsApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
    /* POST endpoints */
    ...postEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get role request by role request id
     */
    getRoleRequestV2: builder.query<RoleRequest, number>({
      query: (roleRequestId) => `/v2/role-requests/${roleRequestId}`,
      transformErrorResponse: async () => {
        message.error("Failed to get role request by role request id", 5);
      },
    }),

    /**
     * Get assigned (proposed and confirmed) role requests by role id and/or employeeId
     */
    getAssignedProjectRoleRequestsOfStandardRole: builder.query<
      RequestReplies[],
      number
    >({
      query: (standardRoleId) =>
        `/project-role-requests/assigned-requests?roleId=${standardRoleId}`,
      transformErrorResponse: async () => {
        message.error(
          "Failed to get project role requests by standard role id",
          5
        );
      },
    }),

    /**
     * Get assigned (proposed and confirmed) role requests by role id and/or employeeId
     */
    getAssignedProjectRoleRequestsOfEmployee: builder.query<
      RequestReplies[],
      number
    >({
      query: (employeeId) =>
        `/project-role-requests/assigned-requests?employeeId=${employeeId}`,
      transformErrorResponse: async () => {
        message.error(
          "Failed to get project role requests by standard role id",
          5
        );
      },
    }),
  };
}
function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = HTTP_METHODS.POST;

  return {
    /**
     * Clone request
     */
    cloneRequest: builder.mutation<number, number>({
      query: (requestId) => ({
        url: `/project-role-requests/${requestId}/clone-request`,
        method: baseMethod,
        body: {},
      }),
      transformErrorResponse: async () => {
        message.error("Error cloning request", 5);
      },
    }),
  };
}

export const {
  /* GET endpoints */
  useGetRoleRequestV2Query,

  //lazy endpoints
  useLazyGetAssignedProjectRoleRequestsOfStandardRoleQuery,
  useLazyGetAssignedProjectRoleRequestsOfEmployeeQuery,
  /* POST endpoints */
  useCloneRequestMutation,
} = projectRoleRequestsApi;
