import React from "react";

import { RequestOverviewDetails } from "@src/types";
import { formatDate } from "@src/utils/helper";
import { Card, Flex } from "antd";
import { CardLabel } from "@src/components/card_label/index";

interface StaffingRequestInformationCardProps {
  request: RequestOverviewDetails;
}

const StaffingRequestInformationCard: React.FC<
  StaffingRequestInformationCardProps
> = ({ request }) => {
  return (
    <Card title="Request" className="info-card">
      <Flex vertical gap={10}>
        <CardLabel label="Initially Sent">
          {formatDate(request.requestSentDate)}
        </CardLabel>
        <CardLabel label="By">{request.sendBy?.fullName}</CardLabel>
        {request.handedOverBy && (
          <CardLabel label="Forwarded by">
            {request.handedOverBy?.fullName}
          </CardLabel>
        )}
      </Flex>
    </Card>
  );
};

export default StaffingRequestInformationCard;
