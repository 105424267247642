import React from "react";

import "./overview_tab.less";
import * as constants from "@src/constants";
import { AdditionalInformationCard } from "@src/features/role_request_details/components/additional_information_card";
import { RoleRequestInformationCard } from "@src/features/role_request_details/components/role_request_information_card";
import { StandardRoleSelectionCard } from "@src/features/role_request_details/components/standard_role_selection_card";
import { RoleRequest } from "@src/types/role_request_types";
import { Row, Col } from "antd";

interface OverviewTabProps {
  request: RoleRequest;
}

const OverviewTab: React.FC<OverviewTabProps> = ({ request }) => {
  return (
    <div className="overview-tab">
      {/* <!-- Top section --> */}
      {request?.status === constants.REQUEST_STATUS.REQUESTED && (
        <Row gutter={[16, 0]}>
          <Col span={10}>
            <StandardRoleSelectionCard request={request} />
          </Col>

          <Col span={5}>
            <RoleRequestInformationCard request={request} />
          </Col>

          <Col span={9}>
            <AdditionalInformationCard request={request} />
          </Col>
        </Row>
      )}

      {/* <!-- Table --> */}
      <Row>
        <Col span={24}>
          Here will be the table or the other charts for non requested requests
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTab;
