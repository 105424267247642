import React from "react";

import { InfoCircleOutlined, CommentOutlined } from "@ant-design/icons";
import { RoleRequestBaseModal } from "@src/components/modals/role_request_modal/index";
import { ROLES } from "@src/constants";
import { ActionHandlers } from "@src/features/role_request_details/components/action_handlers/index";
import { OverviewTab } from "@src/features/role_request_details/components/overview_tab/index";
import {
  setCurrentResourcePlanRequestDetailTab,
  setResourcePlanDetailsModalVisibility,
} from "@src/services/resourcePlanModalSlice";
import { useGetNoteQuery } from "@src/services/slices/notesApiSlice";
import { useGetRoleRequestV2Query } from "@src/services/slices/projectRoleRequestsApi";
import { useAppSelector, RootState } from "@src/setupStore";
import { CustomTabItem } from "@src/types/antd_types";
import { useDispatch } from "react-redux";

import StaffingRequestCommentsTab from "../../../staffing_request_details/components/staffing_request_comments_tab/staffing_request_comments_tab";

const RoleRequestDetailsModal: React.FC = () => {
  const dispatch = useDispatch();

  // States
  const {
    resourcePlanModalVisible,
    selectedResourcePlanRequestId,
    currentTab,
  } = useAppSelector((state: RootState) => state.resourcePlanDetailsModalSlice);

  // RTK Queries
  const { data: request, isFetching } = useGetRoleRequestV2Query(
    selectedResourcePlanRequestId,
    {
      skip: !selectedResourcePlanRequestId,
    }
  );

  const { data: notes } = useGetNoteQuery(selectedResourcePlanRequestId, {
    skip: !selectedResourcePlanRequestId,
  });

  const tabs: CustomTabItem[] = [
    {
      key: "0",
      icon: <InfoCircleOutlined />,
      label: `Overview`,
      children: <OverviewTab request={request} />,
    },
    {
      key: "1",
      icon: <CommentOutlined />,
      label: `Comments (${notes?.length})`,
      children: <StaffingRequestCommentsTab notes={notes} />,
    },
  ];

  return (
    <div>
      {!isFetching && (
        <RoleRequestBaseModal
          activeTabKey={currentTab}
          handleClose={() => {
            dispatch(setResourcePlanDetailsModalVisibility(false));
          }}
          handleTabChange={(key: string) =>
            dispatch(setCurrentResourcePlanRequestDetailTab(key))
          }
          modalVisible={resourcePlanModalVisible}
          tabs={tabs}
          actions={<ActionHandlers />}
          requestedRoleId={request?.roleRequestId}
          requestedRoleStatus={request?.status}
          requestedRoleName={request?.standardRoleName}
          isRequestOutdated={false}
          userRole={ROLES.PROJECT_LEAD}
        />
      )}
    </div>
  );
};

export default RoleRequestDetailsModal;
