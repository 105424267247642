import React from "react";

import "./status_renderer.less";
import { DraftBadge } from "@src/components/app_layout/components/draft_badge";
import { OutdatedBadge } from "@src/components/outdated_badge";
import Status from "@src/components/status/status";
import { REQUEST_STATUS, ROLES } from "@src/constants";
import { CustomCellRendererProps } from "ag-grid-react";

interface StatusRendererProps {
  props: CustomCellRendererProps;
}

const StatusRenderer: React.FC<StatusRendererProps> = ({ props }) => {
  const status = props?.value;
  const assignedEmployeeFullName =
    props.data?.assignedEmployee?.fullName ||
    props.data.assignedEmployeeFullName;
  const { outdated, daysUntilDeletion } = props.data;

  if (status === REQUEST_STATUS.DRAFT) return <DraftBadge />;

  return outdated && props.context?.view === ROLES.PROJECT_LEAD ? (
    <OutdatedBadge daysUntilDeletion={daysUntilDeletion} />
  ) : (
    <Status
      status={status}
      assignedEmployeeFullName={assignedEmployeeFullName}
    />
  );
};

export default StatusRenderer;
