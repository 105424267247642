import React from "react";

import fixedbottom from "@src/assets/icons/fixedbottom-tech.svg";
import floating from "@src/assets/icons/floating-tech.svg";
import hydrogen from "@src/assets/icons/hydrogen.svg";
import { CardLabel } from "@src/components/card_label/index";
import { ProjectPhaseChip } from "@src/components/project_phase_chip/index";
import Status from "@src/components/status/status";
import { RequestOverviewDetails } from "@src/types";
import { formatDate, getWorkloadStartDate } from "@src/utils/helper";
import { Card, Flex, Row, Col } from "antd";
import "./project_information_card.less";

interface ProjectInformationCardProps {
  request: RequestOverviewDetails;
  status: string;
}

const ProjectInformationCard: React.FC<ProjectInformationCardProps> = ({
  request,
  status,
}) => {
  const scopeDescription: string =
    request?.childRequest?.scopeDescription ?? request?.scopeDescription;
  const specificRole: string =
    request?.childRequest?.specificRole ?? request?.specificRole;
  const projectIdentifier: string =
    request?.childRequest?.projectIdentifier ?? request?.projectIdentifier;

  const technologyIcons = {
    Floating: floating,
    "Fixed bottom": fixedbottom,
    Hydrogen: hydrogen,
  };

  function getTechnologyName(technologies: string): string {
    if (!technologies) return "";
    return technologies
      .split(", ")
      .filter((tech) => tech !== "Hydrogen")
      .join(", ");
  }

  function getTechnologyIcon(technologies: string): string {
    if (!technologies) return "";
    if (technologies.includes("Hydrogen")) return hydrogen;
    const technologyName = getTechnologyName(technologies);
    return technologyIcons[technologyName] || "";
  }

  return (
    <Card title={request.projectName} className="info-card">
      <Row>
        {/*left side*/}
        <Col span={8}>
          <Flex vertical gap={10}>
            <CardLabel label="Phase" showToolTip={false}>
              {" "}
              <ProjectPhaseChip
                projectPhase={request.projectPhase}
                compact={false}
              />
              {request.stageGateDate && (
                <span>{`(End: ${formatDate(request.stageGateDate)})`}</span>
              )}
            </CardLabel>
            <CardLabel label="Status">
              <Status status={status} />
            </CardLabel>
            <CardLabel label="Project Lead">{request.projectLead}</CardLabel>
          </Flex>
        </Col>

        {/*middle side*/}
        <Col span={8}>
          <Flex vertical gap={10}>
            <CardLabel label="Region / Country">
              {request.region || "N.A."} / {request.country}
            </CardLabel>
            <CardLabel label="Technology" showToolTip={false}>
              {getTechnologyIcon(request.technologies) && (
                <img
                  src={getTechnologyIcon(request.technologies)}
                  alt="project technology"
                  className="project-information-card-table-technology-icon"
                />
              )}
              <span className="project-information-card-table-technology-name">
                {getTechnologyName(request.technologies)}
              </span>
            </CardLabel>

            <CardLabel label="Workload Start" showToolTip={false}>
              {getWorkloadStartDate(request)}
            </CardLabel>
          </Flex>
        </Col>

        {/*right side*/}
        <Col span={8}>
          <Flex vertical gap={10}>
            <CardLabel label="Scope Description">{scopeDescription}</CardLabel>
            <CardLabel label="Project Identifier">
              {projectIdentifier}
            </CardLabel>
            <CardLabel label="Specific Role">{specificRole}</CardLabel>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectInformationCard;
