import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourcePlanDetailsModalState } from "@src/types";

const initialState: ResourcePlanDetailsModalState = {
  resourcePlanModalVisible: false,
  selectedResourcePlanRequestId: null,
  currentTab: "0",
  selectedStandardRole: null,
};

const roleRequestDetailsModalSlice = createSlice({
  name: "resourcePlanDetailsModal",
  initialState: initialState,
  reducers: {
    setSelectedStandardRoleState: (
      state: ResourcePlanDetailsModalState,
      modalVisible: PayloadAction<any>
    ) => {
      state.selectedStandardRole = modalVisible.payload;
    },

    /**
     * Set the visibility of the resource plan details modal
     *
     * @param state
     * @param modalVisible
     */
    setResourcePlanDetailsModalVisibility: (
      state: ResourcePlanDetailsModalState,
      modalVisible: PayloadAction<boolean>
    ) => {
      state.resourcePlanModalVisible = modalVisible.payload;
    },

    /**
     * Set the selected resource plan request id
     *
     * @param state
     * @param selectedRoleRequestId
     */
    setSelectedResourcePlanRequestId: (
      state: ResourcePlanDetailsModalState,
      selectedRoleRequestId: PayloadAction<number>
    ) => {
      state.selectedResourcePlanRequestId = selectedRoleRequestId.payload;
    },

    /**
     * Set the current tab
     *
     * @param state
     * @param currentSelectedTab
     */
    setCurrentResourcePlanRequestDetailTab: (
      state: ResourcePlanDetailsModalState,
      currentSelectedTab: PayloadAction<string>
    ) => {
      state.currentTab = currentSelectedTab.payload;
    },

    clearResourcePlanModal: () => initialState,
  },
});

export const {
  setSelectedStandardRoleState,
  setResourcePlanDetailsModalVisibility,
  setSelectedResourcePlanRequestId,
  setCurrentResourcePlanRequestDetailTab,
  clearResourcePlanModal,
} = roleRequestDetailsModalSlice.actions;

export default roleRequestDetailsModalSlice;
