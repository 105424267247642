// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-request-information-card .ant-card {
  height: 100%;
}
.role-request-information-card .ant-row-space-between {
  height: 190px;
  flex-direction: column;
}
.role-request-information-card__joint-venture-label {
  display: flex;
  align-items: center;
}
.role-request-information-card__joint-venture-label svg {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/features/role_request_details/components/role_request_information_card/role_request_information_card.less"],"names":[],"mappings":"AAEI;EACE,YAAA;AADN;AAII;EACE,aAAA;EACA,sBAAA;AAFN;AAME;EACE,aAAA;EACA,mBAAA;AAJJ;AAEE;EAKI,gBAAA;AAJN","sourcesContent":[".role-request-information-card {\n  .ant {\n    &-card {\n      height: 100%;\n    }\n\n    &-row-space-between {\n      height: 190px;\n      flex-direction: column;\n    }\n  }\n\n  &__joint-venture-label {\n    display: flex;\n    align-items: center;\n\n    svg {\n      margin-left: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
