import React from "react";

import { ReactComponent as PencilIcon } from "@src/assets/icons/pencil.svg";
import { CardLabel } from "@src/components/card_label/index";
import { RoleRequest } from "@src/types/role_request_types";
import { Card, Flex, Button } from "antd";

interface AdditionalInformationCardProps {
  request: RoleRequest;
}
const AdditionalInformationCard: React.FC<AdditionalInformationCardProps> = ({
  request,
}) => {
  return (
    <Card
      title="Additional Information"
      className="info-card"
      extra={
        <Button
          icon={<PencilIcon />}
          size="small"
          color="default"
          variant="filled"
          onClick={() => console.log("Edit additional information modal opens")}
        />
      }
    >
      <Flex vertical gap={16}>
        <CardLabel label="Specific role">{request?.specificRole}</CardLabel>
        <CardLabel label="Project identifier">
          {request?.projectIdentifier}
        </CardLabel>
        <CardLabel label="Scope">{request?.scope}</CardLabel>
      </Flex>
    </Card>
  );
};

export default AdditionalInformationCard;
