import React, { useMemo, useEffect } from "react";

import "./standard_role_selection_card.less";
import { CardLabel } from "@src/components/card_label/index";
import { setSelectedStandardRoleState } from "@src/services/resourcePlanModalSlice";
import { useGetStandardRolesQuery } from "@src/services/slices/standardRolesApi";
import { useAppDispatch } from "@src/setupStore";
import { StandardRole } from "@src/types";
import { RoleRequest } from "@src/types/role_request_types";
import { Form, Select, Card, Button, Flex, Popover, Tooltip } from "antd";
import { DefaultOptionType } from "antd/es/select";

interface StandardRoleSelectionCardProps {
  request: RoleRequest;
}

type StandardRoleSelectionFormValues = {
  basicRole: string;
  roleSpecification: string;
};

const StandardRoleSelectionCard: React.FC<StandardRoleSelectionCardProps> = ({
  request,
}) => {
  const dispatch = useAppDispatch();
  const initialFormValues: StandardRoleSelectionFormValues = {
    basicRole: request?.basicRoleName,
    roleSpecification: getDisplayedRoleSpecificationName(
      request?.roleSpecificationName
    ),
  };

  // States
  const [basicRoleRelatedStandardRoles, setBasicRoleRelatedStandardRoles] =
    React.useState<StandardRole[]>([]);
  const [selectedStandardRole, setSelectedStandardRole] =
    React.useState<StandardRole | null>(null);

  // hooks
  const { data: standardRoles } = useGetStandardRolesQuery();
  const [form] = Form.useForm<StandardRoleSelectionFormValues>();

  const standardRolesMap: Map<string, StandardRole[]> = useMemo(() => {
    return standardRoles
      .filter((role: StandardRole) => !role.onlyVisibleForAdmin)
      .reduce((map, role) => {
        const roleName = role.basicRole?.basicRoleName;
        if (!map.has(roleName)) {
          map.set(roleName, []);
        }

        map.get(roleName).push(role);
        return map;
      }, new Map<string, StandardRole[]>());
  }, [standardRoles]);

  useEffect(() => {
    if (!standardRoles || !request) return;

    const currentSelectedStandardRole: StandardRole = standardRoles.find(
      (standardRole: StandardRole) =>
        standardRole.standardRoleName === request.standardRoleName
    );

    setSelectedStandardRole(currentSelectedStandardRole);
    setBasicRoleRelatedStandardRoles(
      standardRolesMap.get(request.basicRoleName)
    );
  }, [standardRoles, request]);

  /**
   * Get basic role options
   */
  function getBasicRoleOptions(): DefaultOptionType[] {
    return [...standardRolesMap.keys()]?.map((basicRoleName: string) => ({
      value: basicRoleName,
      label: basicRoleName,
    }));
  }

  /**
   * Get role specification options
   */
  function getRoleSpecificationOptions(): DefaultOptionType[] {
    return basicRoleRelatedStandardRoles?.map((standardRole: StandardRole) => ({
      value: standardRole?.standardRoleId,
      label: getRoleSpecificationLabel(standardRole),
      disabled: !standardRole?.teamLead,
    }));
  }

  /**
   * Get role specification label. Show tooltip if no team lead is assigned.
   *
   * @param standardRole   Standard role
   */
  function getRoleSpecificationLabel(
    standardRole: StandardRole
  ): React.ReactNode {
    return (
      <Tooltip
        title={
          !standardRole?.teamLead &&
          "No team lead assigned to the role, contact administrator for further details"
        }
      >
        {getDisplayedRoleSpecificationName(
          standardRole?.roleSpecification?.roleSpecificationName
        )}
      </Tooltip>
    );
  }

  /**
   * Get displayed role specification name
   *
   * @param roleSpecificationName   Role specification name
   */
  function getDisplayedRoleSpecificationName(
    roleSpecificationName: string
  ): string {
    return roleSpecificationName || "--None--";
  }

  /**
   * Handle change of basic role
   *
   * @param selectedBasicRoleName   Selected basic role name
   */
  function onChangeBasicRole(selectedBasicRoleName: string): void {
    setSelectedStandardRole(null);

    const standardRoles: StandardRole[] = standardRolesMap.get(
      selectedBasicRoleName
    );
    setBasicRoleRelatedStandardRoles(standardRoles);

    if (standardRoles.length === 1 && !standardRoles[0].roleSpecification) {
      // if there is only one role or no role specification than it should be selected automatically
      const selectedStandardRole = standardRoles[0];

      form.setFieldsValue({
        roleSpecification: getDisplayedRoleSpecificationName(
          selectedStandardRole?.roleSpecification?.roleSpecificationName
        ),
      });

      form.validateFields(["roleSpecification"]).then(() => {
        setSelectedStandardRole(selectedStandardRole);
      });
    } else {
      // should reset role specification if there are multiple roles to select
      form.setFieldsValue({ roleSpecification: undefined });
      dispatch(setSelectedStandardRoleState(null));
    }
  }

  /**
   * Handle change of role specification
   *
   */
  function onChangeRoleSpecification(selectedStandardRoleId: number): void {
    const selectedRole: StandardRole = basicRoleRelatedStandardRoles.find(
      (standardRole: StandardRole) =>
        standardRole.standardRoleId === selectedStandardRoleId
    );
    setSelectedStandardRole(selectedRole);
    dispatch(setSelectedStandardRoleState(selectedRole));
  }

  /**
   * Check if role specification is disabled
   */
  function isRoleSpecificationDisabled(): boolean {
    return (
      !basicRoleRelatedStandardRoles ||
      basicRoleRelatedStandardRoles?.length === 0 ||
      (basicRoleRelatedStandardRoles?.length === 1 &&
        !basicRoleRelatedStandardRoles[0].roleSpecification)
    );
  }

  return (
    <Card title="Role" className="standard-role-selection-card info-card">
      {/* <!-- Basic Role Selection--> */}
      <Form form={form} layout="vertical" initialValues={initialFormValues}>
        <Form.Item label="Standard role" name="basicRole">
          <Select
            showSearch
            placeholder="Select a basic role"
            onChange={onChangeBasicRole}
            options={getBasicRoleOptions()}
          />
        </Form.Item>

        {/* <!-- Role Specification Selection--> */}
        <Form.Item label="Role Specification" name="roleSpecification">
          <Select
            showSearch
            disabled={isRoleSpecificationDisabled()}
            onChange={onChangeRoleSpecification}
            placeholder="Select a specific role"
            options={getRoleSpecificationOptions()}
          />
        </Form.Item>
      </Form>

      {/* <!-- Team Lead and Description--> */}
      <Flex justify="space-between">
        <div>
          {selectedStandardRole?.teamLead && (
            <CardLabel label="Responsible Team lead">
              {selectedStandardRole?.teamLead?.fullName}
            </CardLabel>
          )}
        </div>
        <div>
          <Popover
            overlayStyle={{ maxWidth: "500px", whiteSpace: "pre-line" }}
            trigger="click"
            placement="right"
            title={selectedStandardRole?.standardRoleName}
            content={selectedStandardRole?.description || "No description"}
          >
            <Button disabled={!selectedStandardRole} size="large">
              Show Description
            </Button>
          </Popover>
        </div>
      </Flex>
    </Card>
  );
};

export default StandardRoleSelectionCard;
